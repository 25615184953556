.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 64px;
  color: #FFF;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.2); }
