.container {
  display: flex;
  flex-direction: column;
  max-width: 1135px;
  margin: 0 auto;
  padding: 0 30px;
  height: 100%; }

.tabs {
  padding-top: 30px;

  + .listAndChat {
    padding-top: 30px; } }

.listAndChat {
  flex: 1;
  display: flex;
  align-items: stretch;
  padding-top: 50px; }

.column {
  display: flex;
  flex-direction: column; }

.columnLabel {
  height: 28px;
  display: flex;
  justify-content: space-between; }

.columnContent {
  position: relative;
  flex: 1; }

.list {
  composes: column;
  flex: 1;
  min-width: 280px;
  margin-right: 30px; }

.chat {
  composes: column;
  flex: 3; }

.chatLabel {
  composes: columnLabel; }

.listContent {
  composes: columnContent; }

.chatContent {
  composes: columnContent;
  min-height: 0; }

.chatContentInner {
  height: calc(100% - 30px);
  border: 1px solid var(--mercury);
  border-radius: 6px;
  overflow: auto; }

.hidden {
  display: none; }

@media (max-width: 767px) {
  .container {
    padding: 0; }

  .tabs {
    padding-right: 30px;
    padding-left: 30px;

    + .listAndChat {
      padding-top: 0; } }

  .listAndChat {
    flex-direction: column;
    padding: 0; }

  .list {
    margin-right: 0;
    padding: 30px 30px 0; }

  .chatLabel {
    height: auto;
    padding: 0; }

  .chatContentInner {
    border-width: 1px 0 0;
    height: 100%; } }
