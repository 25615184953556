.container {
  padding-top: 10px; }

.heading {
  display: block;
  padding-left: 30px; }

.headingIcon {
  float: left;
  margin: -1px 0 0 -30px; }

.form {
  display: none;
  padding: 10px 0 20px; }

.formVisible {
  composes: form;
  display: block; }

.status {
  display: inline-block;
  margin: -2px 15px -2px 0;
  padding: 2px 7px;
  background: var(--light-gray);
  border-radius: 4px; }

.retry {
  margin-right: 15px; }
