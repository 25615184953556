.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 71px;
  padding: 15px;
  text-align: center; }

.containerTall {
  composes: container;
  min-height: 91px; }

.date {
  position: absolute;
  top: 10px;
  right: 10px;
  text-transform: uppercase; }

.expander {
  display: inline-block;
  margin: -14px 0 -14px -10px;
  vertical-align: 5px; }

.details {
  margin: 15px -15px -15px;
  padding: 10px 0 10px 30px;
  border-top: 1px solid var(--mercury);
  background: #FFF;
  text-align: left; }

.ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none; }

.li {
  flex: 1;
  padding-right: 30px; }

.other {
  display: inline-block; }

@media (max-width: 1023px) {
  .date {
    position: static; }

  .expander {
    display: none; }

  .details {
    display: none; } }

@media (max-width: 767px) {
  .container {
    min-height: 57px;
    padding: 7px 12px;
    text-align: left; }

  .containerTall {
    min-height: 77px; }

  .date {
    position: absolute;
    text-align: right; }

  .dateLabel {
    display: block; } }
