.empty {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  padding-top: 56.25%; }

.emptyInner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px; }

.processing {
  composes: empty;
  background-image: linear-gradient(62deg, var(--secondary-color-l80), var(--secondary-color-l90)); }

.processingInner {
  composes: emptyInner;
  text-align: center;

  p {
    max-width: 416px; } }

.stichingImage {
  margin-bottom: 30px; }
