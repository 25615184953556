.ellipsis {
  span:first-child {
    animation: dot1 1s infinite;

    + span {
      animation: dot2 1s infinite;

      + span {
        animation: dot3 1s infinite; } } } }

@keyframes dot1 {
  0% {
    opacity: 0; }

  25% {
    opacity: 1; }

  85% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@keyframes dot2 {
  0% {
    opacity: 0; }

  25% {
    opacity: 0; }

  50% {
    opacity: 1; }

  85% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@keyframes dot3 {
  0% {
    opacity: 0; }

  50% {
    opacity: 0; }

  75% {
    opacity: 1; }

  85% {
    opacity: 1; }

  100% {
    opacity: 0; } }
