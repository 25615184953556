.field {
  position: relative;
  border: 1px solid var(--mercury);

  form {
    display: flex; } }

.textarea {
  flex: 1; }

.btnSend {
  margin: -1px -1px 0 0; }

.actions {
  min-height: 30px;
  padding: 10px 0 15px; }
