.placeholder {
  opacity: 0.5; }

.pill {
  display: inline-block;
  padding: 7px 8px;
  margin-right: 10px;
  border-radius: 14px;
  background: rgba(var(--grayish-brown-rgb), 0.07);
  line-height: 14px; }

.pillX {
  display: inline-block;
  cursor: pointer;
  margin-left: 8px; }
