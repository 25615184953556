.container {
  display: inline-block;
  vertical-align: middle;
  margin: -12px;
  font-size: 1px; }

.icon {
  padding: 12px; }

// noLeftOverlay

.noLeftOverlay {
  margin-left: 0; }
