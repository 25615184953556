.container {
  position: relative;
  margin: 19px auto 85px;
  border-top: 1px solid var(--mercury);
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 50px; } }

.inner {
  position: absolute;
  top: -20px;
  left: 50%;
  padding: 0 20px;
  background: #FFF;
  transform: translate(-50%, 0);
  white-space: nowrap; }

.h1 {
  display: inline;
  vertical-align: middle;
  font-weight: 400;
  font-size: 25px;
  line-height: 39px;
  text-transform: uppercase; }
