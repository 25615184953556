.opacity-enter {
  opacity: 0;
  transition: 0.2s opacity; }

.opacity-enter-active {
  opacity: 1; }

.opacity-exit {
  opacity: 1;
  transition: 0.2s opacity; }

.opacity-exit-active {
  opacity: 0; }
