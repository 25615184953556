.container {
  margin-left: auto; }

.btnHamburger {
  position: relative;
  line-height: 0; }

.line {
  position: absolute;
  left: 10px;
  width: 24px;
  height: 3px;
  border-radius: 2px;
  background: var(--deep-blue);
  transition: 0.15s all; }

.line1 {
  composes: line;
  top: 11px; }

.line2 {
  composes: line;
  top: 19px; }

.line3 {
  composes: line;
  top: 27px; }

.hamburgerBadge {
  position: absolute;
  top: -3px;
  left: 100%;
  margin-left: -5px; }

.btnHamburgerOpened {
  composes: btnHamburger;

  .line1 {
    top: 19px;
    transform: rotate(45deg); }

  .line2 {
    opacity: 0; }

  .line3 {
    top: 19px;
    transform: rotate(-45deg); } }

.menuContainer {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  overflow: hidden; }

.menu {
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  background: #FFF; }

.menu-enter {
  opacity: 0;
  transform: translate(0, -100%);
  transition: 0.2s all; }

.menu-enter-active {
  opacity: 1;
  transform: translate(0, 0); }

.menu-exit {
  opacity: 1;
  transform: translate(0, 0);
  transition: 0.2s all; }

.menu-exit-active {
  opacity: 0;
  transform: translate(0, -100%); }

.menuItem {
  + .menuItem {
    margin-top: 1px; } }

.menuLink {
  display: block;
  padding: 0 20px;
  background: var(--deep-blue);
  font-size: 18px;
  line-height: 56px;
  color: #FFF; }

.menuBadge {
  position: relative;
  top: -8px;
  margin-left: 8px; }
