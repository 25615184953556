.container {
  position: relative;
  overflow: hidden; }

.input {
  position: absolute;
  top: -50px;
  left: 0;
  opacity: 0;
  cursor: pointer; }
