.container {
  display: flex;
  flex-direction: column;
  height: 100%; }

.connectionDetails {
  background: var(--deep-blue-light);
  border-bottom: 1px solid var(--mercury); }

.messages {
  position: relative;
  flex: 1; }

.messageField {
  padding: 0 30px;

  @media (max-width: 767px) {
    padding: 0 12px; } }
