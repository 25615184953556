.container {
  display: inline-block;
  min-width: 16px;
  padding: 0 5px;
  border-radius: 10px;
  line-height: 16px;
  text-align: center;
  background: var(--coral-red);
  color: #FFF;

  * {
    vertical-align: middle; } }
