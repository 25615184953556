.container {
  padding: 70px;
  min-height: 40vh; }

.content {
  max-width: 1000px;
  margin: 0 auto; }

.members {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-row-gap: 40px;
  grid-column-gap: auto;
  justify-content: space-between; }

.title {
  margin-bottom: 3px; }

.link {
  margin-bottom: 9px; }

@media (max-width: 767px) {
  .container {
    margin: 70px 35px;
    padding: 0; }

  .members {
    grid-template-columns: 100%; } }
