.confirmNote {
  padding-top: 12px;
  padding-left: 10%;
  padding-right: 10%; }

.confirmModalContainer {
  text-align: center;
  min-height: 80px; }

.errorAlert {
  width: auto;
  margin-top: 1em; }
