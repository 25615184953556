.filter {
  width: 45em;
  margin: 1em auto 1em;
  padding: 10px;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    margin: 6em auto -3em;
    width: 26em;
    flex-direction: column; } }

.totals {
  margin-top: 0.5em;
  text-align: center; }

.jumpToContainer {
  text-align: left;
  padding-top: 1em; }

.jumpToForm {
  p, input {
    margin-top: 1em;
    margin-bottom: 1em; }
  input {
    border: 1px solid black;
    padding: 4px;
    max-width: 10em; }
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1; }

.searchCmp {
  margin-left: 20px;

  @media (max-width: 767px) {
    margin-top: 20px; } }
