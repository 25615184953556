.base {
  position: relative;
  margin-bottom: 20px;
  padding: 15px 50px 15px 15px; }

.success {
  composes: base;
  background: var(--deep-blue); }

.error {
  composes: base;
  background: var(--coral-red); }

.btnClose {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -22px; }

// sticky

.sticky {
  position: fixed;
  top: 75px;
  right: 0;
  left: 0;
  z-index: 10;

  @media (max-width: 767px) {
    top: 90px; } }

// offset

.offset-single {
  margin-bottom: 15px; }

.offset-double {
  margin-bottom: 30px; }

// centered

.centered {
  text-align: center; }
