.container {
  position: relative;
  border: 1px solid var(--mercury);
  border-radius: 6px;
  overflow: hidden; }

.content {
  position: relative;
  padding: 10px 15px; }

.contentWithActions {
  composes: content;
  padding-bottom: 35px; }

.link {
  display: block;

  &:hover,
  &:active,
  &:global(.focus-visible) {
    background: var(--ice);

    ~ .messageLink {
      opacity: 0.8; } }

  &.selected {
    background: var(--deep-blue-light);
    cursor: default; } }

.messageLink {
  position: absolute;
  bottom: 10px;
  left: 15px;
  pointer-events: none; }

.pitchtapeLink {
  position: absolute;
  bottom: 10px;
  right: 15px; }

.count {
  position: absolute;
  top: 10px;
  right: 10px; }

@media (max-width: 767px) {
  .contentWithActions {
    padding-bottom: 40px; }

  .pitchtapeLink {
    bottom: 0;
    right: 0;
    padding: 10px 15px; } }
