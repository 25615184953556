.base {
  cursor: pointer;
  color: var(--primary-color);

  &:hover,
  &:global(.focus-visible),
  &:active {
    opacity: 0.75; }

  &.active {
    cursor: default;
    opacity: 1; } }

.link {
  cursor: pointer;
  color: var(--primary-color);
  text-decoration: underline;

  &:hover,
  &:global(.focus-visible),
  &:active {
    opacity: 0.75; }

  &.active {
    cursor: default;
    opacity: 1; } }

.tab {
  display: inline-block;
  padding: 5px 0;
  cursor: pointer;
  font-size: 15px;
  text-transform: uppercase;
  color: var(--grayish-brown);

  &:hover,
  &:global(.focus-visible),
  &:active {
    opacity: 0.6; }

  &.active {
    padding-bottom: 3px;
    border-bottom: 2px solid var(--primary-color-active);
    opacity: 1;
    cursor: default;
    font-weight: 500;
    color: var(--grayish-brown); }

  &:global(.focus-visible) {
    color: var(--deep-blue); } }

.mainnav {
  position: relative;
  display: flex;
  align-items: center;
  height: 75px;
  font-size: 15px;
  letter-spacing: 0.25px;

  &:hover,
  &:active,
  &:global(.focus-visible), {
    box-shadow: inset 0 -3px 0 var(--secondary-color); } }

.activePage {
  box-shadow: inset 0 -3px 0 var(--secondary-color);

  .icon {
    margin-right: 5px;
    font-size: 0; } }

.mainnavSub {
  display: block;
  align-items: center;
  margin: 0 -20px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 0.25px;

  &:hover,
  &:active,
  &:global(.focus-visible) {
    box-shadow: inset 3px 0 0 var(--secondary-color); }

  .icon {
    display: inline-block;
    vertical-align: 5px;
    margin-right: 5px;
    font-size: 0; } }

.pagination {
  composes: tab;
  padding: 10px; }

.sidebar {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--grayish-brown);

  &:hover,
  &:global(.focus-visible),
  &:active {
    opacity: 0.6; }

  &.active {
    cursor: default;
    opacity: 1;
    font-weight: 500;
    color: var(--grayish-brown); }

  &:global(.focus-visible) {
    opacity: 0.6; }

  @media (max-width: 1100px) {
    &.active {
      margin-bottom: -5px;
      padding-bottom: 4px;
      border-bottom: 1px solid var(--grayish-brown);
      cursor: default; } }

  @media (max-width: 767px) {
    font-size: 14px; } }

.inherit {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;

  &:hover,
  &:global(.focus-visible),
  &:active {
    opacity: 0.7; } }

.expander {
  composes: base;
  border-bottom: 1px dashed;
  white-space: nowrap;
  color: var(--dusty-gray); }

.expanderInactive {
  composes: expander;
  cursor: default; }

.iconOnLeft {
  composes: base;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 5px;
    font-size: 0; } }

.iconOnLeftLarger {
  composes: iconOnLeft;
  font-size: 16px; }

.pitchtapeFooter {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 17px;
  text-transform: uppercase;
  color: var(--deep-blue);

  &.active {
    background: var(--primary-color);
    color: #FFF;
    cursor: default; } }

.listWithIcon {
  composes: base;
  padding: 3px 0;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 5px;
    font-size: 0; } }

// disabled

.disabled {
  pointer-events: none;
  opacity: 0.5; }

// color

.color-black {
  color: var(--grayish-brown);

  &:hover,
  &:global(.focus-visible),
  &:active {
    opacity: 1;
    color: var(--deep-blue); } }

.color-green {
  color: var(--green); }

.color-white {
  color: #FFF; }

.color-deepBlue {
  color: var(--deep-blue); }

// inlineBlock

.inlineBlock {
  display: inline-block;
  max-width: 100%; }

// offset

.offset-half-left {
  margin-left: 0.5em; }

.offset-single-left {
  margin-left: 1em; }
