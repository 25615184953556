.container {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  color: var(--coral-red); }

.dt {
  margin-top: 10px;

  &:first-child {
    margin-top: 0; } }

.dd {
  margin: 0; }

.p {
  margin: 0; }
