.container {
  position: relative;
  overflow: hidden; }

.analytics,
.cta {
  float: left;
  width: 100%; }

.analytics {
  filter: blur(3px);
  opacity: 0.1; }

.cta {
  position: relative;
  margin-left: -100%;
  text-align: center; }

.ctaInner {
  max-width: 570px;
  margin: 0 auto;
  padding: 50px;

  @media (max-width: 767px) {
    padding: 0; } }

.list {
  margin: 30px 0; }

.button {
  margin: 30px 0;
  text-align: center; }
