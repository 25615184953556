.base {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 200;
  transform: translate(-50%, 0);
  padding: 15px 20px;
  margin-top: 10px;
  background: var(--deep-blue);
  border-radius: 6px;
  color: #FFF; }

.tooltip {
  composes: base;
  max-width: 350px;

  @media (max-width: 767px) {
    max-width: 355px; }

  @media (max-width: 375px) {
    max-width: calc(100vw - 20px); } }

.tourTooltip {
  composes: base;
  padding: 20px;

  .footer {
    display: block;
    margin-top: 15px;
    text-align: right; } }

.white {
  composes: base;
  padding: 25px;
  background: #FFF;
  color: var(--grayish-brown);

  .arrow {
    border-bottom-color: #FFF; } }

.tourModal {
  composes: white;

  .footer {
    display: block; } }

.welcome {
  composes: white;
  width: 100%;
  max-width: 730px;
  padding: 0; }

.copy {
  composes: welcome; }

.error {
  composes: white; }

.arrow {
  display: none;
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -7px;
  border: solid transparent;
  border-width: 0 7px 7px 7px;
  border-bottom-color: var(--deep-blue); }

.overlay {
  position: fixed;
  top: -200px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--deep-blue-rgb), 0.7);
  z-index: 200; }

.loader {
  position: fixed;
  z-index: 201;
  top: 50%;
  left: 50%;
  padding: 2px;
  background: #FFF;
  transform: translate(-50%, -50%);
  font-size: 0; }

.footerContainer {
  text-align: center; }

.footer {
  display: inline-block; }

// direction

.direction-up {
  transform: translate(-50%, -100%);
  margin-top: -10px;

  .arrow {
    top: 100%;
    margin-top: 0;
    border-width: 7px 7px 0 7px;
    border-top-color: var(--deep-blue);
    border-bottom-color: transparent; } }

// offset

.offset-0 {
  margin-top: 0px; }

// centered

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 90vh;
  margin: 0;
  transform: translate(-50%, -50%);
  overflow: auto;

  @media (max-width: 767px) {
    top: 50%;
    right: 0;
    left: 0;
    max-width: 355px;
    margin: auto;
    transform: translate(0, -50%); }

  @media (max-width: 375px) {
    max-width: calc(100vw - 20px); } }
