.container {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 30px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 767px) {
    padding-right: 12px;
    padding-left: 12px; } }

.loader {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center; }

.date {
  text-align: center;
  margin: 20px 0;

  &:first-child {
    margin-top: 0; } }

.messagesAvailable {
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 5px 10px;
  border-radius: 3px 3px 0 0;
  transform: translate(-50%, 0);
  background: var(--deep-blue);
  cursor: pointer; }
