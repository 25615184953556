.container {
  height: 80px; }

.floating {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background: var(--ice);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.16); }
