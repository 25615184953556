.base {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  + .base {
    .text,
    .attachment {
      margin-top: 5px; } } }

.bubble {
  padding: 10px 15px;
  border-radius: 5px;
  background: var(--ice);
  max-width: 70%;

  @media (max-width: 767px) {
    max-width: 80%; } }

.text {
  composes: bubble; }

.attachment {
  max-width: 70%;

  .image {
    display: block;
    border-radius: 5px;
    width: 300px;
    max-width: 100%; } }

.file {
  composes: bubble;
  display: flex;
  max-width: none;
  color: #131522; }

.fileIcon img {
  width: 16px; }

.fileName {
  margin: 0 10px 0 5px; }

.mine {
  composes: base;
  align-items: flex-end;
  text-align: right;

  .bubble {
    background: var(--deep-blue-light); } }

.yours {
  composes: base; }
