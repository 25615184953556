.base {
  position: relative;
  vertical-align: middle; }

// offset

.offset-right-5 {
  margin-right: 5px; }

.offset-right-10 {
  margin-right: 10px; }
