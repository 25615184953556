.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 30px 50px;
  text-align: center; }

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  flex: 1; }

.paragraph {
  max-width: 400px;
  margin: 0 auto; }

.buttons {
  margin-top: 30px; }

.status {
  margin-bottom: -30px; }
