.base-footer {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  margin-top: 20px;
  justify-content: center;

  @media (max-width: 480px) {
    grid-auto-flow: row; } }

.white-footer {
  composes: base-footer; }

.welcome-header {
  padding: 20px;
  background-image: linear-gradient(225deg, var(--secondary-color), var(--secondary-color));
  border-radius: 6px 6px 0 0;
  text-align: center; }

.welcome-content {
  padding: 40px 70px;

  @media (max-width: 767px) {
    padding: 30px 35px; } }

.welcome-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 40px; }

.copy-header {
  composes: welcome-header; }

.copy-content {
  padding: 35px 40px;

  @media (max-width: 767px) {
    padding: 25px 30px; } }

.copy-footer {
  composes: welcome-footer; }
