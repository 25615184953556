
.congratsModalContainer {
  text-align: center; }

.middle {
  margin-top: 10px; }

.bottom {
  margin-top: 25px;
  padding-left: 20%;
  padding-right: 20%;
  text-align: center;
  margin-bottom: 10px; }

