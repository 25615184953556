@keyframes blink {
  from {
    opacity: 0; }

  to {
    opacity: 1; } }

@keyframes halfblink {
  from {
    opacity: 0.6; }

  to {
    opacity: 0; } }

.base {
  text-align: center;
  font-size: 0; }

.image {
  animation: blink 0.5s infinite alternate; }

.centered {
  composes: base;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.blank {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #FFF;
  animation: halfblink 0.5s infinite alternate; }

// offset

.offset-single {
  margin: 25px 0; }

.offset-quarter {
  margin-top: 6px; }
