.row {
  + .row {
    margin-top: 20px; } }

.rowOverall {
  composes: row;
  padding: 10px 0 0; }

.rowBody {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start; } }

.stars {
  width: 260px;

  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 0.5em; } }
