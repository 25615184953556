.container {
  margin-top: 30px;
  margin-bottom: 50px; }

.list {
  margin: 6px 0 6px 10px; }

@media (max-width: 1000px) {
  .container {
    display: flex; }

  .topic {
    flex: 1; } }

@media (max-width: 480px) {
  .container {
    display: block;
    margin-top: 20px; }

  .topic {
    flex: 1; } }
