.bar {
  margin-bottom: 20px;
  border-radius: 3px;
  background: var(--mango); }

.progress {
  height: 5px;
  border-radius: 3px 0 0 3px;
  background: var(--deep-blue); }

.values {
  display: flex;
  justify-content: space-around;
  text-align: center; }
