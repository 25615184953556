.btnCollapse {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
  text-align: right; }

.btnCollapseActive {
  composes: btnCollapse;
  width: 100%; }
