.knowledge_center {
  color: var(--primary-color);

  a {
    color: var(--dark-blue); } }

.knowledge_center h1 {
  color: var(--primary-color);
  -webkit-text-fill-color: var(--primary-color);
  background: none; }
