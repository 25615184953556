.container {
  position: relative;
  z-index: 1;
  min-height: 40px;
  padding: 0 28px 0 15px;
  background: #FFF;
  border: 1px solid var(--deep-blue);
  border-radius: 4px;
  font-size: 16px;
  line-height: 38px;

  &.clearable {
    padding-right: 56px; }

  @media (max-width: 767px) {
    &.clearable {
      padding-right: 89px; } } }

.error {
  border-color: var(--coral-red); }

.loading {
  animation: border-blink 0.5s infinite alternate; }

@keyframes border-blink {
  from {
    box-shadow: inset 0 0 30px -30px rgba(var(--deep-blue-rgb), 0.2); }

  to {
    box-shadow: inset 0 40px 30px -30px rgba(var(--deep-blue-rgb), 0.2); } }

.withIcon {
  padding-left: 30px; }

.icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  text-align: center; }

.arrowDown {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 1px;

  @media (max-width: 767px) {
    right: 17px; } }
