.container + .container {
  margin-top: 100px; }

.h1 {
  margin-bottom: 30px; }

.p {
  margin-top: -27px;
  margin-bottom: 30px; }

// separate

.separate {
  border-top: 1px solid var(--dusty-gray);
  padding-top: 50px;

  .container + & {
    margin-top: 50px; } }
