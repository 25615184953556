.input {
  position: relative; }

.base {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 7px 15px;
  border-radius: 4px;
  border: 1px solid var(--deep-blue);
  font-size: 16px;
  line-height: 24px;
  color: var(--grayish-brown);
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

  &[readonly] {
    background: #f4f3fd;
    border-color: #f4f3fd;
    color: #aaaaaa;
    border-radius: 12px; } }


.error {
  composes: base;
  border: 1px solid var(--coral-red); }

.activeDropdown {
  composes: base;
  border-radius: 5px 5px 0 0; }

.transparent {
  composes: base;
  background: none;
  border: none;
  transition: none;
  height: 38px;

  &:global(.focus-visible) {
    border: none; } }

.isPassword {
  padding-right: 35px; }

.containerAutosize {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  max-width: 100%; }

.autosize {
  display: inline-block;
  min-width: 10px;
  max-width: 100%;
  width: 10px;
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding: 0; }

.inputCopy {
  position: absolute;
  left: 0;
  top: 100%;
  width: auto;
  padding: 0;
  font-size: 14px;
  white-space: nowrap; }

.autocomplete {
  composes: transparent;
  padding: 0; }

.withCounter {
  padding-right: 60px; }
