.dataTable {
  padding: 2em;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (max-width: 767px) {
    padding: 1em;
    margin-left: auto;
    margin-right: auto; }
  th {
    padding-left: 5px;
    background: var(--primary-color);
    color: var(--light-gray);
    font-weight: normal;
    min-width: 2em;
    @media (max-width: 767px) {
      min-width: 2em; } }

  td {
    border: none;
    padding: 10px;
    min-width: 4em;
    max-width: 20em;
    @media (max-width: 767px) {
      min-width: 4em;
      max-width: 16em; } }

  tr {
    text-align: left; }

  tr:hover {
    background: var(--deep-blue-light); } }

.loadMore {
  margin-left: auto;
  margin-right: auto;
  width: 10em;
  @media (max-width: 767px) {
    margin-left: 0.5em; } }

.containerSearch {
  display: flex;
  justify-content: right;
  margin-right: 2em;
  margin-top: 3em;

  div {
    display: flex;
    align-items: center; }

  @media (max-width: 767px) {
    justify-content: center;
    width: 100%; } }
