.container {
  position: relative; }

.btnShow {
  position: absolute;
  top: 0;
  right: 0; }

.btnShowInner {
  height: 40px;
  padding: 0 10px;
  line-height: 40px; }
