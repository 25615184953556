.btnClear {
  position: absolute;
  top: 0;
  right: 30px;
  bottom: 0;
  padding-right: 1px;

  @media (max-width: 767px) {
    right: 44px; }

  &:before {
    content: " ";
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    border-right: 1px solid var(--mercury); } }

.btnClearInner {
  position: relative;
  margin: -3px -8px 0 0;

  @media (max-width: 767px) {
    margin-right: 0; } }
