.base {
  flex: 1;

  @media (max-width: 767px) {
    margin-top: 30px;

    &:first-child {
      margin-top: 0; } }

  + .base {
    margin-left: 30px;

    @media (max-width: 767px) {
      margin-left: 0; } } }

.inline {
  display: inline-block; }

.horizontalSubmit {
  composes: base;
  flex: 0;
  margin-top: 25px;
  white-space: nowrap; }

// offset

.offset-15 {
  margin-left: 15px; }

// size

.size-half {
  flex: none;
  width: calc(50% - 15px);

  @media (max-width: 767px) {
    width: auto; } }
