.container {
  position: relative;
  overflow: hidden; }

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;

  &:focus + .label {
    opacity: 0.7; } }

.label {
  position: relative;
  display: flex;
  width: 100%;
  cursor: pointer;
  text-align: center;
  justify-content: flex-start;
  gap: 1em;

  svg {
    align-self: flex-start;
    justify-self: flex-start; }

  &:hover {
    opacity: 0.7; } }

.showLabels {
  border: solid 1px var(--dark-blue);
  border-radius: 4px;
  padding: 5px;
  margin: 1em;
  width: 8em; }


.showLabelsActive {
  composes: showLabels;
  border: solid 1px var(--secondary-color); }

.labelText {
  justify-self: center;
  align-self: center; }
