.container {
  position: relative;
  padding: 20px;
  background: #FFF;
  border-radius: 10px;

  + .container {
    margin-top: 25px; }

  + .noMargin {
    margin-top: 0; } }

// highlight

.highlight {
  padding: 1px;
  background-image: linear-gradient(241deg, var(--secondary-color), var(--secondary-color)); }

.highlightInner {
  padding: 19px;
  background: #FFF;
  border-radius: 9px; }

// padding

.padding-0 {
  padding: 0; }

.padding-50 {
  padding: 50px; }
