.container {
  display: flex;
  height: 100%;
  justify-content: center;
  background: var(--primary-color); }

.main {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 50%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 35px;
  background: #FFF;
  text-align: center;
  border-radius: 10px; }

.mainInner {
  margin: auto; }

.intro {
  margin-bottom: 30px; }

.form {
  max-width: 370px;
  margin: 35px auto;
  text-align: left; }

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: auto;
  background-image: linear-gradient(225deg, var(--secondary-color-l80), var(--secondary-color-l90)); }

.heroText {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 570px;
  padding: 50px 35px;
  margin: auto; }

@media (max-width: 1023px) {
  .blockquote {
    padding: 35px; } }

@media (max-width: 767px) {
  .container {
    display: block; }

  .main {
    width: auto; }

  .hero {
    width: auto;

    &:before {
      display: none; } }

  .heroImage {
    display: none; } }
