.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--mercury); }

.image {
  display: block;
  max-width: 100%;
  max-height: 100%; }

.btnRemove {
  position: absolute;
  top: -13px;
  right: -13px;

  &:before {
    content: " ";
    position: absolute;
    top: 12px;
    left: 12px;
    width: 20px;
    height: 20px;
    background: var(--mercury); }

  * {
    position: relative; } }

.notimage {
  width: 100%;
  padding: 5px 5px 0;
  text-align: center; }
