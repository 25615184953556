.btnClose {
  position: absolute;
  top: 0;
  right: 0; }

.container {
  display: flex;
  flex-direction: column; }

.align {
  text-align: center;

  @media (min-width: 768px) {
    padding: 0 75px; } }
